/* ------- configure css tokens ------- */
:root {
  --background: #ffffff;
  --background-hover: #021220;
  --text-light: #393939;
  --text-light-hover: #ffffff;
  --background-1: #f7f7f7;
  --background-2: #ededed;
  --background-3: #fcfcfc;
  --accent-1: #6f97ff;
  --accent-2: #6fca67;
  --accent-4: #f65a5a;
  --accent-3: #e7d960;
  --accent-5: #c94fa7;
  --alternateLight: #9ea3b4;

  /* ----- constant color config ------- */
  --navigationBg: #002444;
  --navigation-text: #002444;
  --neutralWhite: #ffffff;
  --themeBg: #30364b;
}

[data-theme="dark"] {
  --background: #021220;
  --background-hover: #ffffff;
  --navigation-text: #ffffff;
  --text-light: #ffffff;
  --text-light-hover: #021220;
  --background: #021220;
  --background-1: #05192b;
  --background-2: #011627;
  --background-3: #021b31;
  --alternateLight: #434d6d;
}
